body {
  // font-family: "MinionPro" !important;
  -webkit-overflow-scrolling: touch;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.avatar-staff {
  border-radius: 50%;
}

button,
button:active,
button:focus {
  outline: none !important;
}

textarea,
textarea:focus {
  outline: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 965px !important;
  }
}

@media (min-width: 1380px) {
  .container {
    max-width: 1350px !important;
  }
}

/* Header */

.home-lending {
  position: relative;
  overflow: hidden;
}

.banner-body-1 {
  width: 800px;
  height: 700px;
  position: absolute;
  background: linear-gradient(to right, #25c5fc, #255cf8);
  z-index: 2;
  border-radius: 50px;
  transform: rotate(45deg);
  top: -195px;
  right: -60px;
}

.banner-body-2 {
  width: 400px;
  height: 400px;
  position: absolute;
  background: linear-gradient(to right, #25c5fc, #255cf8);
  z-index: 2;
  border-radius: 50px;
  transform: rotate(45deg);
  top: 0;
  left: -250px;
}

header {
  padding: 15px 0;
  position: relative;
  z-index: 2;
}

input,
input:before,
input:after {
  outline: none;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.navbar-home {
  display: flex;
  align-items: center;
  position: relative;

  .navbar-home-toggler {
    display: none;
    right: 0;
    position: absolute;
    border: none;
    background: no-repeat;
    font-size: 32px;
  }
  #navbar-home-collapse.navbar-home-collapse {
    margin-left: auto;

    ul {
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        font-size: 19px;
        margin: 0px 25px;
        font-weight: bold;
        position: relative;
        &:last-child {
          margin-right: 0;
        }

        a {
          color: #343434;
          &:not(.icon-social) {
            position: relative;
            display: inline-block;
          }
        }

        .btn-get-started {
          border: none;
          background: #fff;
          color: #343434;
          font-weight: bold;
          border-radius: 5px;
          padding: 12px 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.navbar-home-toggler-close {
  position: absolute;
  top: 5%;
  right: 30px;
  font-size: 25px;
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  border-radius: 50%;
}

a.icon-social {
  font-size: 20px;
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff !important;
  background: linear-gradient(to right, #fb8c00, #eedb39);
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  margin-right: 15px;
}

a.icon-social:before {
  display: none;
}

.navbar-home .navbar-home-collapse ul li a {
  color: #727475;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

/* End Header */

/* Slider */

.slider-home {
  position: relative;
  z-index: 2;
  padding: 100px 0 20px;

  .slider-meta {
    h5 {
      color: #e02020;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 85px;
      font-weight: bold;
      color: #000;
      margin-bottom: 20px;
    }
    p {
      color: #4f4f4f;
      font-size: 18px;
      max-width: 80%;
      font-weight: 500;
      margin-bottom: 30px;
    }
    .btn-start-now {
      background: #4797f8;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 12px 35px;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.35s ease-in-out;
      &:hover {
        color: #fff;
        background: darken(#4797f8, 10%);
      }
    }
  }

  .slider-banner {
    .banner-ipad {
      width: 140%;
      margin-top: -105px;
      position: relative;
      button {
        border: none;
        background: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}

/* End Slider */

/* Solution */

.header-title {
  text-align: center;
  h4 {
    font-size: 55px;
    font-weight: bold;
    margin-bottom: 35px;
  }

  p {
    font-size: 23px;
    color: #7f8186;
    font-weight: 500;
    margin-bottom: 60px;
  }
}

.section-solution {
  position: relative;
  padding: 200px 0 0;

  .solution-box {
    text-align: center;
    padding-bottom: 40px;

    .solution-meta {
      height: 55px;
      margin-bottom: 20px;
    }
    h2 {
      font-weight: bold;
      font-size: 26px;
    }
  }

  .header-solution-tab {
    padding-bottom: 40px;
  }

  .body-solution {
    background: #f6f8f9;
    padding: 80px 0 60px;
    position: relative;

    .tab-solution-left {
      position: static;

      .solution-banner {
        position: absolute;
        width: 45%;
        left: 0;
        top: 0;
      }
    }

    .tab-solution-right {
      padding-left: 45px;

      .solution-content {
        .solution-item {
          margin-bottom: 30px;
          h5 {
            font-size: 23px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          p {
            font-size: 22px;
            color: #606266;
            line-height: 34px;
            padding-left: 40px;
            position: relative;

            &:before {
              content: "";
              width: 15px;
              height: 15px;
              background: #ccc;
              left: 0;
              position: absolute;
              border-radius: 50%;
              top: 11px;
            }
          }
        }
      }
    }
  }
}

/* Edn Solution */

/* Promotional */

.section-promotional {
  padding: 120px 0 100px;
}

.promotion-item {
  padding: 0 20px;
}

/* End Promotional */

/* Feedback */

.section-feedback {
  padding: 160px 0 60px;

  .feedback-title {
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 40px;
    h4 {
      font-size: 45px;
      font-weight: bold;
      margin-bottom: 40px;
      span {
        display: inline-block;
        padding-bottom: 35px;
      }
    }
  }

  .BrainhubCarousel {
    padding-bottom: 120px;
    position: relative;
    .BrainhubCarousel__trackContainer {
      padding-bottom: 10px;
      .BrainhubCarousel__track {
        padding-bottom: 10px;
      }
    }
  }
  .feedback-icon {
    position: absolute;
    bottom: 40px;
    background: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 30px 30px #e9e9e9;
    font-size: 25px;
    color: #8ba2ad;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
    span {
      border-color: #8ba2ad;
      display: inherit;
    }
    &:hover,
    &:focus {
      background: #0091ff;
      box-shadow: 0 30px 30px #0091ff4f;
      color: #fff;
    }
  }
}

.feedback-item-container {
  padding: 0 25px;
  .feedback-item {
    padding: 20px;
    border: 1px solid #ededed;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(206, 234, 255, 0.3);
    .feedback-item-meta {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      flex-wrap: wrap;
      .meta-avatar {
        width: 100px;
        height: 100px;
        margin-right: 15px;
      }
      .meta-title {
        width: calc(100% - 115px);
        h4 {
          color: #0292ff;
          margin-bottom: 5px;
          font-size: 20px;
        }
        p {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
          color: #28343a;
        }
      }
    }
    .feedback-item-content {
      font-size: 18px;
      font-weight: 500;
      color: #334249;
    }
  }
}

/* End Feedback */

/* Photos */

.section-photos {
  padding-bottom: 100px;
  .photos-title {
    text-align: center;
    margin-bottom: 80px;
    h4 {
      font-size: 36px;
      font-weight: bold;
      span {
        color: #fa6400;
      }
    }
  }
  .photos-container {
    padding: 0 15px;

    .photos-item {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      height: 430px;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      }
      &:hover {
        .meta-title {
          button {
            background: rgba(21, 28, 50, 1);
          }
        }
      }

      .meta-avatar {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .meta-title {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        button {
          width: 100%;
          padding: 10px;
          background: no-repeat;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 2px;
          font-size: 20px;
          transition: all 0.35s ease-in-out;
        }
      }
    }
  }

  .BrainhubCarousel__customArrows {
    position: absolute;
  }
}

/* End Photos */

/* Contact */

.section-contact {
  padding: 40px 0 20px;

  .contact-home-left {
    padding: 20px 0;
    p {
      font-size: 25px;
      color: #d0eefe;
      font-weight: 500;
    }
    h4 {
      font-size: 42px;
      color: #fff;
      margin-bottom: 40px;
    }
  }

  .form-contact {
    background: #fff;
    padding: 35px 30px 20px;
    border-radius: 10px;

    h4 {
      font-size: 23px;
      margin-bottom: 15px;
    }

    p {
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 30px;

      &.noti-contact {
        font-size: 15px;
        margin: 0;
        color: #0091ff;
        font-weight: bold;
      }
    }

    .input-custom {
      position: relative;
      height: 55px;
      border: 1px solid #ccccd5;
      margin-bottom: 20px;
      border-radius: 8px;
      transition: all 0.35s ease-in-out;
      width: 100%;
      span {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
        font-size: 17px;
        line-height: 17px;
        font-weight: 500;
        color: #a5a5a5;
        z-index: 2;
        transition: all 0.35s ease-in-out;
        background: #fff;
        cursor: text;
        i {
          color: red;
          font-size: 20px;
        }
      }

      input {
        font-size: 18px;
        font-weight: 500;
        position: absolute;
        bottom: 50%;
        transform: translate(0, 50%);
        left: 0;
        padding: 0 15px;
        border: none;
        transition: all 0.35s ease-in-out;
        z-index: 1;
        height: initial;
        &:focus {
          box-shadow: none;
        }
      }

      &.focus {
        span {
          top: 0;
          transform: translate(0, 0);
          font-size: 14px;
        }

        input {
          bottom: 2px;
          transform: translate(0, 0);
        }
      }
    }
    button {
      margin-top: 10px;
      padding: 10px 0;
      background: #0091ff;
      font-size: 20px;
      width: 100%;
      border-radius: 8px;
      border: none;
    }
  }
}

/* End Contact */

/* Footer */

footer.footer {
  background: #151c32;
  .footer-menu-title {
    color: #fff;
    font-size: 25px;
    margin-bottom: 40px;
  }
  .footer-menu {
    ul {
      list-style: none;
      text-align: left;
      font-size: 23px;
      font-weight: 500;
      padding: 0;
      li {
        margin-bottom: 15px;
        a {
          color: #66676a;
          &:hover {
            color: lighten($color: #66676a, $amount: 10%);
          }
        }
      }
    }
  }
  .footer-icon-store {
    padding: 15px 0 40px;

    &:last-child {
      padding: 0;
    }
  }

  .footer-bottom {
    font-size: 20px;
    padding: 20px 0;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        color: #66676a;
      }

      &.footer-bottom-menu {
        li {
          margin-right: 40px;
        }
      }

      &.footer-bottom-social {
        text-align: right;
        li {
          margin-left: 10px;

          &.language-select {
            margin-right: 50px;
            p {
              margin: 0;
              font-weight: 500;
            }
            span {
              height: 15px;
              display: inline-block;
            }
          }
          &:not(.language-select) {
            span {
              width: 30px;
              height: 30px;
              background: #51545e;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              font-size: 15px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/* End Footer */

/* Custom Input */

.custom-checkbox-item {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-item .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #2d100f;
}

.custom-checkbox-item .checkmark:after {
  content: "";
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}

.custom-checkbox-item input:checked ~ .checkmark:after {
  visibility: visible;
  opacity: 1;
}

.custom-checkbox-item .checkmark:after {
  left: 6px;
  top: 0px;
  width: 10px;
  height: 16px;
  border: solid #2d100f;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox-item p {
  margin: 0;
}

/* End Custom Input */

/* Policy Page */

.policy-page {
  padding-bottom: 100px;
  .policy-title {
    padding: 140px 0;
    text-align: center;
    font-size: 40px;
    margin-bottom: 60px;
    background-size: cover;

    h4 {
      font-size: 40px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  p {
    &.sub-title {
      font-weight: 500;
      margin: 5px 0;
    }
  }
}

@media (max-width: 1400px) {
  header {
    padding: 10px 0;
  }
  .navbar-home {
    #navbar-home-collapse.navbar-home-collapse {
      margin-left: auto;
      ul {
        li {
          font-size: 13px;
          margin: 0px 20px;
          .btn-get-started {
            padding: 8px 20px;
          }
        }
      }
    }
  }
  .banner-body-1 {
    width: 585px;
    height: 550px;
    border-radius: 50px;
    transform: rotate(45deg);
    top: -170px;
  }
  .banner-body-2 {
    width: 270px;
    height: 270px;
    border-radius: 35px;
    left: -15%;
  }

  .slider-home {
    padding: 60px 0 20px;

    .slider-meta {
      h5 {
        font-size: 21px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 50px;
      }

      p {
        font-size: 15px;
      }
    }
    .slider-banner {
      .banner-ipad {
        margin-top: -45px;

        button {
          width: 100px;
        }
      }
    }
  }

  .section-solution {
    padding: 100px 0 0;
    .header-solution {
      padding-bottom: 20px;
    }

    .header-solution-tab {
      padding-bottom: 10px;
    }

    .solution-box {
      padding-bottom: 10px;
      .solution-meta {
        height: 40px;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 20px;
      }
    }

    .body-solution {
      padding: 40px 0 60px;

      .tab-solution-right {
        padding-left: 20px;
        .solution-content {
          .solution-item {
            margin-bottom: 15px;

            h5 {
              font-size: 17px;
              margin-bottom: 15px;
            }

            p {
              font-size: 15px;
              line-height: 25px;
              padding-left: 25px;

              &:before {
                width: 10px;
                height: 10px;
                top: 7px;
              }
            }
          }
        }
      }
    }
  }

  .header-title {
    h4 {
      font-size: 32px;
      margin-bottom: 15px;
    }

    p {
      font-size: 18px;
    }
  }

  .section-promotional {
    padding: 60px 0;
  }

  .section-contact {
    padding: 40px 0 20px;

    .contact-home-left {
      p {
        font-size: 18px;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 30px;
      }
    }

    .form-contact-container {
      padding: 25px;

      .form-contact {
        padding: 25px 20px 15px;

        h4 {
          font-size: 18px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          margin-bottom: 20px;
        }
        .input-custom {
          height: 40px;
          margin-bottom: 10px;
          border-radius: 5px;
          span {
            font-size: 13px;
            line-height: 17px;
            i {
              font-size: 13px;
            }
          }
        }
        button {
          padding: 8px 0;
          font-size: 15px;
          border-radius: 5px;
        }
      }
    }
  }
  .section-feedback {
    padding: 60px 0 30px;
    .feedback-title {
      h4 {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
    .feedback-item-container {
      padding: 0 15px;
      .feedback-item {
        .feedback-item-meta {
          margin-bottom: 15px;
          .meta-avatar {
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
          .meta-title {
            width: calc(100% - 75px);
            h4 {
              font-size: 16px;
            }
            p {
              font-size: 13px;
            }
          }
        }
        .feedback-item-content {
          font-size: 13px;
        }
      }
    }
    .BrainhubCarousel {
      padding-bottom: 100px;
    }
    .feedback-icon {
      width: 40px;
      height: 40px;
      box-shadow: 0 30px 15px #e9e9e9;
      font-size: 20px;
    }
  }

  .section-photos {
    padding-bottom: 50px;

    .photos-title {
      margin-bottom: 50px;
    }
    .photos-container {
      .photos-item {
        height: 300px;
      }
    }
  }
  footer.footer {
    .footer-top {
      padding: 100px 0 10px;
    }
    .footer-menu-title {
      font-size: 18px;
      margin-bottom: 25px;
    }
    .footer-menu {
      ul {
        font-size: 15px;
      }
    }
    .footer-bottom {
      font-size: 15px;
      padding: 10px 0;
    }
  }
}

.modal-video {
  .modal-content {
    background: #4797f8;
    border-radius: 20px;
    padding: 15px 15px 10px;
  }
}

@media (max-width: 1199px) {
  .banner-body-1 {
    width: 440px;
    height: 440px;
    border-radius: 30px;
    top: -90px;
  }
  .slider-home {
    .slider-banner {
      .banner-ipad {
        margin-top: 0;
        width: 110%;
      }
    }
  }
}

@media (max-width: 991px) {
  .banner-body-1 {
    width: 350px;
    height: 350px;
    border-radius: 30px;
    top: -50px;
  }
  .banner-body-2 {
    width: 150px;
    height: 150px;
  }
  .slider-home {
    padding: 60px 0 20px;

    .slider-meta {
      h5 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 30px;
      }

      p {
        font-size: 13px;
      }
      .btn-start-now {
        padding: 8px 20px;
        font-size: 14px;
      }
    }
    .slider-banner {
      .banner-ipad {
        margin-top: 0;
        width: 110%;

        button {
          width: 60px;
        }
      }
    }
  }

  .header-title {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
  }

  .section-solution {
    padding: 70px 0 0;

    .body-solution {
      padding: 0;

      .tab-solution-right {
        padding-left: 20px;
        .solution-content {
          .solution-item {
            margin-bottom: 10px;

            h5 {
              font-size: 17px;
              margin-bottom: 10px;
            }

            p {
              font-size: 13px;
              line-height: 20px;
              padding-left: 25px;

              &:before {
                width: 10px;
                height: 10px;
                top: 7px;
              }
            }
          }
        }
      }
    }
  }

  .section-contact {
    .contact-home-left {
      p {
        font-size: 15px;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 21px;
        margin-bottom: 25px;
      }
    }
  }

  .section-promotional {
    padding: 40px 0;
  }

  .section-photos {
    .photos-container {
      .photos-item {
        height: 200px;
        .meta-title {
          button {
            padding: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
