/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@font-face {
  font-family: "PlayfairDisplay";
  src: url("./assets/fonts/PlayfairDisplay-Regular.otf") format("opentype");
}

@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url("./assets/fonts/PlayfairDisplay-Bold.otf") format("opentype");
}

@font-face {
  font-family: "MinionPro";
  src: url("./assets/fonts/MinionPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "MinionPro-Semi";
  src: url("./assets/fonts/MinionPro-Semibold.otf") format("opentype");
}

/* @font-face {
  font-family: "Allison";
  src: url("./assets/fonts/Allison\ Tessa\ Oblique.ttf") format("truetype");
} */

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans-Semi";
  src: url("./assets/fonts/OpenSans-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Homizio";
  src: url("./assets/fonts/Homizio-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Homizio-Medium";
  src: url("./assets/fonts/Homizio-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Homizio-Light";
  src: url("./assets/fonts/Homizio--Light.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Bold";
  src: url("./assets/fonts/BebasNeue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Marion Bold";
  src: url("./assets/fonts/Marion-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}


@media (max-width: 991px) {
  body {
    background-size: 100% 100%;
    object-fit: cover;
    background-position: right bottom;
  }
}

@media (min-width: 992px) {
  .dashboard-form {
    background-size: 100% 100%;
    object-fit: cover;
    background-position: right bottom;
  }
}
