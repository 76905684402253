@import "assets/scss/index.scss";
@import "~font-awesome/css/font-awesome.min.css";
// .App {
//   text-align: center;
// }

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 40vmin;
//   pointer-events: none;
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

html,
body,
#root,
.dashboard-form,
.dashboard-form-1 {
  min-height: 100%;
  display: flex;
  width: 100%;
}

ul {
  list-style: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// scss-lint:disable all
.animated {
  animation-duration: 1s;
  // animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
@mixin transition {
  transition: all 0.35s ease-in-out;
}

@media (min-width: 992px) {
  #root {
    align-items: center;
    justify-content: center;
  }
}

.dashboard-form {
  margin: 0 auto;
  overflow: hidden;
  @media (min-width: 992px) {
    width: 500px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}

.dashboard-form-1 {
  flex-direction: column;
  h4 {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .header-form {
    display: flex;
    background: #f2f2f2;
    padding: 15px;
    align-items: center;

    > span {
      width: 80px;
      margin-right: 15px;
    }
    .header-meta {
      width: calc(100%);

      h5 {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }

  .body-form {
    padding: 15px;

    .group-button {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      button {
        width: calc(50% - 10px);
        background: #fff;
        color: #333;
        font-weight: 500;

        &.isSelect {
          background: #0091ff;
          border: 1px solid #0091ff;
          color: #fff;
        }
      }
    }

    .form-input {
      label {
        font-weight: 500;
      }
      .group-input {
        position: relative;
        span {
          color: #dfe2e6;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translate(0, -50%);
          font-size: 18px;
          &.icon-phone {
            font-size: 26px;
          }
        }
        input {
          padding-left: 35px;
          &:disabled {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }

  .coupon-check {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 1rem;
    .group-check {
      display: flex;
      line-height: 38px;
      align-items: center;
      color: #666666;
      font-weight: 500;

      > span {
        margin-right: 15px;
      }
      p {
        margin: 0;
        line-height: initial;
        > span {
          color: #0091ff;
          cursor: pointer;
        }
      }
    }
    .input-check {
      max-height: 0;
      transition: max-height 1s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      input {
        margin: 10px 0 5px;
      }
      &.open {
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }
    }
  }

  .btn-submit {
    margin: auto 15px 15px 15px;
    background: #0091ff;
    font-weight: 500;
    border-color: #0091ff;
  }
}
.form-service {
  padding: 15px 15px 0 15px;
  &.isSelected {
    padding-bottom: 60px;
  }
}
.search-service {
  margin-bottom: 20px;
  position: relative;
  input {
    border-radius: 20px;
    padding-right: 30px;
  }
  > span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    color: #bbbbbb;
  }
}

.category-sv-item {
  > button {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    background: #4988cc;
    > span {
      font-size: 18px;
      margin-left: auto;
    }
  }
  .card {
    border: none;
  }
}

.service-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &.selected {
    .item-meta {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .item-meta {
    margin-right: 15px;
    width: 80px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 145, 255, 0.5);
      opacity: 0;
      visibility: hidden;
      transition: all 0.35s ease-in-out;
    }
  }
  .item-content {
    flex: 1;
    font-weight: 500;
    p {
      margin: 0;
    }
    span {
      font-size: 14px;
    }
  }
  > span {
    font-size: 20px;
    margin-left: 15px;
  }
}

.btn-add {
  border: none;
  background: none;
  color: #0091ff;
  text-align: center;
  padding: 5px 0;
  font-weight: 500;
  width: 100%;
}

.btn-ready {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  button {
    background: #0091ff;
    border: none;
  }
}

.modal-success {
  .modal-content {
    border-radius: 10px;
  }
  .modal-body {
    text-align: center;
    padding: 30px 20px;
    img {
      max-width: 70px;
    }
    h5 {
      font-weight: bold;
      font-size: 24px;
      color: #303030;
      &.error-submit {
        color: #ec2049 !important;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 37px;
          margin-right: 10px;
        }
      }
    }
    h4 {
      margin: 15px 0;
      font-weight: bold;
      font-size: 20px;
      color: #303030;
    }
    p {
      color: #7f7f7f;
      font-weight: 500;
    }
    button {
      width: 100%;
      background: #4886ff;
      border-color: #4886ff;
    }
  }
}

.error-form {
  color: red;
  margin: 0;
}

.loading-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  @include transition();
  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.banner-page {
  text-align: center;
  h4 {
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #bf333e;
    color: #bf333e;
  }
  img {
    max-width: 400px;
    max-height: 400px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 20px #fff;
    display: block;
    margin-bottom: 40px;
  }

  a {
    font-weight: 500;
    border: none;
    font-size: 20px;
    background: #bf333e;
    display: inline-flex;
    padding-right: 10px;
    text-transform: uppercase;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    padding-right: 10px;
    align-items: center;
    color: #fff;

    &:hover,
    &:focus,
    &:visited {
      color: #fff;
      text-decoration: none;
    }

    span {
      &:first-child {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: #bf333e;
        margin-right: 10px;
        font-weight: bold;
        font-size: 20px;
        border: 2px solid;
        i {
          -webkit-animation: spin 2000ms linear infinite; /* Safari 4.0 - 8.0 */
          animation: spin 2000ms linear infinite;
        }
      }
    }
  }
}

.group-policy {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  button {
    margin: 0 auto 20px;
    display: block;
    width: calc(100% - 40px);
    padding: 6px 0;
    font-weight: 500;
    background: #0091ff;
    border: none;
    color: #fff;
    border-radius: 5px;
    &:disabled {
      background: #999;
    }
  }
}

#form-policy {
  padding: 20px;
  font-weight: 500;
  color: #333;
  text-align: justify;
  flex: 1;
  overflow: auto;
  h5 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    color: #000;
    text-transform: uppercase;
  }
  label {
    margin-bottom: 15px;
  }
}

.modal-register-success {
  .modal-content {
    border-radius: 20px;
  }
  .modal-header {
    border: none;
    margin-bottom: 0px;

    .modal-title {
      position: relative;

      .banner-gift {
        display: flex;
        max-width: 85%;
        margin: -90px auto 0;
        max-height: 195px;
        @media (max-width: 767px) {
          max-height: 110px;
        }
        img {
          object-fit: contain;
        }
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        line-height: initial;
        color: #111111;
      }
    }
  }

  .modal-body {
    h4 {
      font-family: "Marion Bold";
      font-weight: normal;
      text-align: center;
    }
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

/* Standard syntax */
@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

.shopInfo {
  text-align: center;
}